.overlay {
  background: var(--white);
  z-index: 2;
}

.scores {
  flex: 0 0 auto;
  flex-direction: row;
}

.score {
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-color: transparent;
  background-color: var(--white);
  max-width: 73px;
  max-height: 73px;
  width: 14vmin;
  height: 14vmin;
  margin: 5vmin;
  border-radius: 15%;
  position: relative;
}

.score .crown {
  font-size: 9vmin;
  transform: translate(5vmin, -9vmin) rotate(30deg);
  color: var(--white);
}

.score.primary .crown {
  transform: translate(-5vmin, -9vmin) rotateZ(-30deg);
  color: var(--black)
}

.score.primary {
  background-color: var(--black);
}

.score__number {
  justify-content: center;
  line-height: 0.9;
  font-size: 36px;
  color: var(--black);
}

.score__name {
  color: var(--black);
}

.primary .score__name,
.primary .score__number {
  color: var(--white);
}

.game {
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.board {
  width: 100%;
  display: block;
  position: relative;
}

.card {
  box-shadow: 0 0 5px var(--dark-grey);
  display: flex;
  position: absolute;
  background-color: var(--white);
  border-radius: 15%;
  border-width: 2px;
  border-color: transparent;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  z-index: 1;
}

.card.primary {
  background-color: var(--black)
}

.card.disabled {
  box-shadow: none;
  background-image: none;
}

button.card {
  font-size: inherit;
}

.number {
  transform: rotateZ(90deg);
  color: var(--black);
}

.laid.card > .number {
  transform: rotateZ(0deg);
}

.highlight {
  box-shadow: 0 0 10px var(--dark-grey);
  border: var(--black) 3px solid;
}

.highlight.primary {
  box-shadow: 0 0 10px var(--dark-grey);
  border-color: var(--white);
}

.card.disabled {
  opacity: 0.15;
}

.card.disabled .number {
  color: var(--white-transparent);
}

.card.played {
  transform: inherit scale(75%);
}
