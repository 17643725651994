/**** BUTTON ****/
button,
.button {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--black);
  text-align: center;
  font-size: calc(10px + 0.5vmin);
  padding: 1vmin;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0.5vmin;
  padding: 0.66rem;
  border: 1px solid transparent;
  border-radius: 3px;
  border-color: var(--black);
}

.button + .button,
button + button
{
  margin-left: 10px;
}

button[disabled],
.button[disabled],
.button-icon[disabled] {
  opacity: 0.4;
  pointer-events: none;
  background: transparent;
}

[role="button"] {
  cursor: pointer;
}

/* primary */
.button-primary {
  padding: 1vmin 1rem;
  border: 1px solid var(--black);
}

.button-primary.active {
  border-color: var(--white);
  background-color: var(--mid-grey);
}

.button-primary:hover,
.button-primary:focus {
  border-color: var(--white);
  color: var(--white);
}

/* button icon */
.button-icon {
  width: 6vmin;
  height: 6vmin;
  cursor: pointer;
  opacity: 0.5;
}

.button-icon + .button-icon {
  margin-left: 3vmin;
}

.button-icon img,
.button-icon__img {
  max-width: 6vmin;
  max-height: 6vmin;
}

/* modal button */
.modal-button {
  width: 50%;
  margin: 0 0 1rem;
}

/* close */
.close {
  transform: rotate(45deg);
}

/* group button */
.button-group {
  width: 100%;
  display: flex;
  justify-content: stretch;
}

.button-group .button,
.group-button {
  border-radius: 0;
  flex: 1 1 100%;
  margin: 0;
}

.button-group .button:first-child {
  border-top-left-radius: 1vmin;
  border-bottom-left-radius: 1vmin;
}

.button-group .button:last-child {
  border-top-right-radius: 1vmin;
  border-bottom-right-radius: 1vmin;
}

.button-group .prefix,
.button-group .suffix {
  flex: 0 0 12.5%;
}

/* secondary */
.button-secondary {
  border-color: transparent;
}

/* link button */
.button-link {
  padding: 0;
  margin: 0;
  font-size: inherit;
  border: 0;
  text-transform: inherit;
  color: inherit;
  line-height: 1.2;
}

/* ***INPUT*** */
input,
.form-control {
  border: 1px solid var(--black);
  background: transparent;
  border-radius: 0.5vmin;
  padding: 1rem;
  width: 100%;
  margin: 0;
  color: var(--black);
  font-size: calc(10px + 0.75vmin);
}

.form-control + .form-control,
input + input,
input + button {
  margin-top: 3vmin;
}

::-webkit-input-placeholder {
  color: var(--black) !important;
}

:-ms-input-placeholder {
  color: var(--black) !important;
}

::placeholder {
  color: var(--black) !important;
}
