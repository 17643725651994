/* variables */

:root {
  --black: #000000cc;
  --dark-grey: #00000044;
  --mid-grey: #ffffff44;
  --mid-grey-solid: #c4c4c4;
  --light-grey: #ffffffcc;
  --white-transparent: #ffffff88;
  --white: #ffffffee;
  --gold: #ff9800;
  --yellow: #ffc107;
  --red: #f44336;
  --green: #8bc34a;
  --blue: #1e88e6;
}

.bg-yellow {
  background-color: var(--yellow);
}
.bg-gold {
  background-color: var(--gold);
}
.bg-red {
  background-color: var(--red);
}
.bg-green {
  background-color: var(--green);
}
.bg-blue {
  background-color: var(--blue);
}
.bg-white {
  background-color: var(--white);
}
.bg-light-grey {
  background-color: var(--light-grey);
}

/* reset */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: var(--black);
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

/* global */

body {
  background-color: var(--mid-grey-solid);
  color: var(--black);
  font-family: "Rubik", serif;
  line-height: 1.4;
}

button,
input,
form,
select,
option {
  font-family: "Rubik", serif;
}

a,
a:visited {
  color: var(--black);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--black);
}

h1,
h2,
h3,
h4,
p {
  margin-bottom: 1.5rem;
}

form {
  margin-bottom: 3rem;
}


ol > li {
  margin-bottom: 1rem;
}

sup {
  font-size: calc(4px + 0.5vmin);
}

/* grid */
.flex-container {
  display: flex;
}

.flex-column {
  flex-flow: column;
}

.flex-item {
  flex: 0 0 auto;
  width: 100%;
}

.flex-col {
  flex: 1 1 auto;
}

.flex-grow-1 {
  flex: 1 0 auto;
}

.flex-grow-2 {
  flex: 2 0 auto;
}

.flex-grow-3 {
  flex: 3 0 auto;
}

.flex-shrink-1 {
  flex: 0 1 auto;
}

.flex-shrink-2 {
  flex: 0 2 auto;
}

.flex-shrink-3 {
  flex: 0 3 auto;
}

.flex-align-center {
  align-items: center;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-align-baseline {
  align-items: baseline;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-stretch {
  justify-content: stretch;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

/* positioning */
.position-realtive {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.stretch {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* text */
.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

/* layout */
.full-width {
  margin: -1rem -1rem 1rem -1rem;
  width: calc(100% + 4vmax);
}

.stretch-horizontally {
  width: 100%;
}

.padding {
  padding: 1rem;
}

.padding-top {
  padding-top: 1rem;
}

.padding-bottom {
  padding-bottom: 1rem;
}

.padding-small {
  padding: 0.5rem 1rem;
}

.no-padding {
  padding: 0 !important;
}

.margin-bottom {
  margin-bottom: 1.5vh;
}

.margin-top {
  margin-top: 1.5vh;
}

.margin-right {
  margin-right: 1.5vh;
}

.margin-left {
  margin-left: 1.5vh;
}

.no-margin {
  margin: 0 !important;
}

.constrain-width {
  max-width: 440px;
  margin: 0 auto;
}

.constrain-width-1024 {
  max-width: 1024px;
  margin: 0 auto;
}

/* images */
img.resposive {
  max-height: auto;
  max-width: auto;
}

/* components */
.App {
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 1vmin);
  min-height: 100vh;
  transition: background-color 0.2s;
  overflow: hidden;
  user-select: none;
}

.Icon {
  font-size: calc(20px + 1vh);
  cursor: pointer;
}

.Score__number {
  font-size: calc(24px + 1vmin);
}

/* styling */
header {
  background: var(--black);
}

footer {
  background: var(--black);
  color: var(--light-grey);
  text-align: center;
}

.app-logo {
  width: 6vmax;
}

.hero {
  color: var(--white);
}

.hero a {
  color: var(--white);
  border-color: var(--white);
}

.hero .button {
  color: var(--red);
  background-color: var(--white);
  border-color: var(--white);
}

.hero h2 {
  font-size: 5vmin;
  padding: 1vmin 0;
}

.list-group {
  list-style: none;
}

.list-group > li {
  padding: 1rem 0;
  border-bottom: 1px solid var(--black);
  display: flex;
  justify-content: space-between;
}

.list-group > li:last-child {
  border-bottom: 0;
}

.bold {
  font-weight: 700;
}

.rank-master {
  color: var(--gold);
}

.rank-grand-master {
  color: var(--red);
}

/* colors */
.color-green {
  color: var(--green);
}

.color-red {
  color: var(--red);
}
