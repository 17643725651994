.navigation > ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
}

.navigation > ul > li {
  padding: 2vmax;
  flex: 0 1 auto;
}

.navigation > ul > li > a,
.navigation > ul > li > a:hover,
.navigation > ul > li > a:visited,
.navigation > ul > li > button {
  color: var(--light-grey);
  position: relative;
}

.navigation > ul > li > a.notification::after {
  position: absolute;
  content: "●";
  color: var(--red);
  top: -7px;
}

.navigation > ul > li.brand-logo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.brand-logo img {
  flex: 1 1 auto;
  opacity: 0.8;
}

.active {
  color: var(--white);
  font-weight: 700;
}
